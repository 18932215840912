import React from "react";
import "./navbar.css";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Body from "../body/body";
import logo_white from "../../assets/logo/floatr-white-letter-logo.png";
import logo_black from "../../assets/logo/floatr-black-letter-logo.png";

function Navbar() {
  const navigation = [
    { name: "Services", href: "#", scrollToElement: "services" },
    { name: "Portfolio", href: "#", scrollToElement: "portfolio" },
    { name: "Careers", href: "#", scrollToElement: "careers" },
  ];

  return (
    <div className="relative z-10 pb-8 bg-gray-800 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
      <svg
        className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
        fill="rgba(31, 41, 55, var(--tw-bg-opacity))"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        aria-hidden="true"
      >
        <polygon points="50,0 100,0 50,100 0,100" />
      </svg>

      <Popover>
        <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
          <nav
            className="relative flex items-center justify-between sm:h-10 lg:justify-start"
            aria-label="Global"
          >
            <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href="?">
                  <span className="sr-only">Floatr</span>
                  <img className="h-16 w-auto sm:h-16" alt="Floatr Logo" src={logo_white} />
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
              {navigation.map((item) => (
                <button
                  key={item.name}
                  onClick={() => {
                    document
                      .getElementById(item.scrollToElement)
                      ?.scrollIntoView({ behavior: "smooth" });
                  }}
                  className="font-medium text-gray-200 hover:text-gray-600"
                >
                  {item.name}
                </button>
              ))}
              <a
                href="https://calendly.com/floatr/30min"
                target="_blank"
                rel="noreferrer"
                className="font-medium text-blue-600 hover:text-blue-500"
              >
                Schedule a Consultation
              </a>
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img className="h-16 w-auto" src={logo_black} alt="" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Close main menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <button
                    key={item.name}
                    onClick={() => {
                      document
                        .getElementById(item.scrollToElement)
                        ?.scrollIntoView({ behavior: "smooth" });
                    }}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </button>
                ))}
              </div>
              <a
                href="https://calendly.com/floatr/30min"
                target="_blank"
                rel="noreferrer"
                className="block w-full px-5 py-3 text-center font-medium text-blue-600 bg-gray-50 hover:bg-gray-100"
              >
                Schedule a Consultation
              </a>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <Body></Body>
    </div>
  );
}

export default Navbar;
