import React from "react";
import "./companies.css";

import ava from "../../assets/clients/ava.png"
import clickasnap from "../../assets/clients/clickasnap.png"
import hollaex from "../../assets/clients/holla.png"
import manyhats from "../../assets/clients/manyhats.svg"
import medchron from "../../assets/clients/medchron.png"
import midas from "../../assets/clients/midascircle.png"
import helpays from "../../assets/clients/helpays.png"
import heroicminds from "../../assets/clients/heroicminds.png"



function Companies() {
  return (
    <div className="lg:flex md:block py-12 bg-gray-200">
      <div className="lg:flex-auto md:block lg:w-1/3 md:w-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base text-gray-600 font-semibold tracking-wide uppercase">
            Trusted by clients from different industries
          </h2>
          <div className="block justify-evenly">
               <img className="inline-block mx-6 w-36" alt="AVAGrows Logo" src={ava} />
               <img className="inline-block mx-6 w-36" alt="ClickASnap Logo" src={clickasnap} />
               <img className="inline-block mx-6 w-36" alt="ManyHats Logo" src={manyhats} />
               <img className="inline-block mx-6 w-36" alt="Medchron Logo" src={medchron} />
               <img className="inline-block mx-6 w-36" alt="Midas Logo" src={midas} />
               <img className="inline-block mx-6 w-36" alt="Hollex Logo" src={hollaex} />
               <img className="inline-block mx-6 w-24" alt="Helppays Logo" src={helpays} />
               <img className="inline-block mx-6 w-32 rounded-lg" alt="Helppays Logo" src={heroicminds} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Companies;
