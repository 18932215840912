import React from "react";
import "./App.css";
import Careers from "./components/careers/careers";
import Companies from "./components/companies/companies";
import Features from "./components/features/features";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import Services from "./components/services/services"
import Work from "./components/work/work";
import CTA from "./components/cta/cta";

function App() {
  return (
     <div className="App">
          <Header></Header>
          <Companies></Companies>
          <Features></Features>
          <Services></Services>
          <Work></Work>
          <Careers></Careers>
          <CTA></CTA>
          <Footer></Footer>
       </div>
  );
}

export default App;
