import {
     SupportIcon,
     LockClosedIcon,
     ScaleIcon,
     UserGroupIcon
   } from "@heroicons/react/outline";
   
   const features = [
     {
       name: "Affordable Pricing and Financing",
       description:
         "We offer great month-to-month pricing models along with financing options and flexible equity/revenue sharing models.",
       icon: ScaleIcon,
     },
     {
       name: "Multitude of Services",
       description:
         "Migrations-to-Deployments, our Engineers have worked with technologies large and small to bring you simple/complex solutions for your business needs.",
       icon: UserGroupIcon,
     },
     {
       name: "24/7 Support and Maintenance",
       description:
         "We take care of maintaining your applications on various cloud platforms, so you can focus on whats more important.",
       icon: SupportIcon,
     },
     {
       name: "Industry Best Practice",
       description:
         "We know how important your software, infrastructure, and data needs are. That's why we follow industry best practice when building and supporting client solutions.",
       icon: LockClosedIcon,
     },
   ];
   
   function Features() {
     return (
       <div id="features" className="py-12 bg-white">
         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
           <div className="lg:text-center">
             <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
               Software + Cloud
             </h2>
             <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
               An efficent way to develop.
             </p>
             <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
               We believe that great software is built by great engineers. So we
               focus on building teams that can interface and work with you to
               build your custom solutions.
             </p>
           </div>
   
           <div className="mt-10">
             <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
               {features.map((feature) => (
                 <div key={feature.name} className="inline-block">
                   <div className="inline-block h-12 w-12 rounded-md text-blue-500">
                     <feature.icon
                       aria-hidden="true"
                     />
                   </div>
                   <p className="text-lg leading-6 font-medium text-gray-900">
                     {feature.name}
                   </p>
                   <dd className="mt-2 text-base text-gray-500">
                     {feature.description}
                   </dd>
                 </div>
               ))}
             </dl>
           </div>
         </div>
       </div>
     );
   }
   
   export default Features;
   