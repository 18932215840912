import React from "react";
import "./cta.css";

function CTA() {
  return (
    <div className="py-16 bg-white">
      <div className="max-w-8xl mx-auto sm:px-6 lg:px-8">
        <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
          <div className="absolute inset-0">
            <img
              className="h-full w-full object-cover"
              src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
              alt="People working on laptops"
            />
            <div className="absolute inset-0 bg-blue-800 mix-blend-multiply" />
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              <span className="block text-white">Kickstart your Journey</span>
              <span className="block text-blue-200">to Modern Tech</span>
            </h1>
            <p className="mt-6 max-w-lg mx-auto text-center text-xl text-blue-200 sm:max-w-3xl">
              Accelerate your software development lifecycle with Floatr. We
              believe in building highly scalable cloud architectures and
              shipping quality code for any business and its needs.
            </p>
            <div className="mt-10 text-center max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://calendly.com/floatr/30min"
                className="items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-500 bg-white hover:bg-blue-50 sm:px-8"
              >
                Schedule a Consultation
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CTA;
