import React from "react";
import "./header.css";

import Navbar from "../navbar/navbar";

function Header() {

  return (
    <div className="relative bg-gray-800 overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <Navbar></Navbar>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
          alt=""
        />
      </div>
    </div>
  );
}

export default Header;
