import React from "react";

import midas from "../../assets/images/midas.png";
import helppays from "../../assets/images/helppays.png";
import medchron from "../../assets/images/medchron.png";
import clickasnap from "../../assets/images/clickasnap.png";
import heroicminds from "../../assets/images/heroicminds.png";
import ava from "../../assets/images/ava.png";
import hollaex from "../../assets/images/hollaex.png";

import angular from "../../assets/icons/angular.png";
import react from "../../assets/icons/react.png";
import ionic from "../../assets/icons/ionic.png";
import nodejs from "../../assets/icons/node-js.png";
import python from "../../assets/icons/python.png";
import aws_black from "../../assets/icons/aws-black.png";
import sketch from "../../assets/icons/sketch.png";
import illustrator from "../../assets/icons/illustrator.png";
import figma from "../../assets/icons/figma.png";


const posts = [
  {
    title: "Heroic Minds",
    category: {
      name: "Mental Health",
      href: "#",
      color: "bg-purple-100 text-purple-800",
    },
    description:
      "Heroic Minds is a resource of psychological, philosophical and life skill exercises. Heroic Minds aims to arm you with the tools to meet the demands of life before they affect your well-being. Become a weapon and a hero. Live a high performing, purpose-driven life.",
    date: "July 2022",
    imageUrl: heroicminds,
    techs: [react, figma, aws_black, nodejs, python],
    author: {
      name: "Heroic Minds",
      website: "https://www.heroicminds.live/",
    },
  },
  {
    title: "ClickASnap",
    category: {
      name: "Social",
      href: "#",
      color: "bg-blue-100 text-blue-800",
    },
    description:
      "ClickASnap provides the best online photo sharing platform to help you share your images and get paid for it. With over 1 million image views per day, ClickASnap is home to a photo community that allows you to promote your photos and grow your profile, while keeping full control and rights over your images. It’s never been easier to get paid for sharing photos.",
    date: "July 2021",
    imageUrl: clickasnap,
    techs: [aws_black, nodejs, python],
    author: {
      name: "ClickASnap",
      website: "https://www.clickasnap.com/",
    },
  },
  {
    title: "AVA Smart Garden",
    category: {
      name: "Home",
      href: "#",
      color: "bg-yellow-100 text-yellow-800",
    },
    description:
      "AVA Byte is a smart indoor garden that helps anyone get growing. Crafted by horticulturists and designers, Byte has been designed to help you harvest the freshest food at your fingertips.",
    date: "June 2021",
    imageUrl: ava,
    techs: [aws_black, nodejs, python],
    author: {
      name: "Ava Grows",
      website: "https://www.avagrows.com/",
    },
  },
  {
    title: "Midas Circle Dashboard",
    category: {
      name: "FinTech",
      href: "#",
      color: "bg-green-100 text-green-800",
    },
    description:
      "Midas Circle's Option Dashboard allows you to see real-time into unusual option activity in the equity markets along with proprietary algorithm alerts and callouts. Comes with a custom dashboard for users and an admin panel for user and content moderation.",
    date: "May 2021",
    imageUrl: midas,
    techs: [angular, ionic, aws_black, nodejs, python],
    author: {
      name: "Midas Circle",
      website: "https://www.the-midascircle.com/",
    },
  },
  {
    title: "Hollex Dashboard",
    category: {
      name: "FinTech",
      href: "#",
      color: "bg-green-100 text-green-800",
    },
    description:
      "HollaEx empowers creators, business, developers, liquidity providers and traders to participate in a digital marketplace that is open and accessible to all. Commited to easy to use crypto software and building on decentralized applications.",
    date: "March 2021",
    imageUrl: hollaex,
    techs: [aws_black, nodejs],
    author: {
      name: "Hollaex",
      website: "https://www.hollaex.com/",
    },
  },
  {
    title: "MedChron",
    category: {
      name: "Medical",
      href: "#",
      color: "bg-red-100 text-red-800",
    },
    description:
      "MedChron is a revolutionary cloud-based, HIPAA-compliant tool that has helped doctors’ offices 10-20x their reimbursements on Medicare CCM/CCP billing.",
    date: "February 2021",
    imageUrl: medchron,
    techs: [aws_black, nodejs, python],
    author: {
      name: "MedChron",
      website: "https://www.medchron.us/",
    },
  },
  {
    title: "Landing Page",
    category: {
      name: "FinTech",
      href: "#",
      color: "bg-green-100 text-green-800",
    },
    description:
      "Helppays, the largest marketplace for short-term peer to peer loans. Lend/Borrow up to $2,000 for up to 9 months. Chat to negotiate terms or give advice and make a meaningful impact within your community!",
    date: "January 2021",
    imageUrl: helppays,
    techs: [ionic, sketch, illustrator],
    author: {
      name: "Helppays",
      website: "https://www.helppays.com/",
    },
  },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

function Work() {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2
            id="portfolio"
            className="text-base text-blue-600 font-semibold tracking-wide uppercase"
          >
            Portfolio
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Our past work.
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Take a look at some of our past work from trusted clients.
          </p>
        </div>
      </div>
      <div className="max-w-7xl mx-auto">
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post) => (
            <div
              key={post.title}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={post.imageUrl}
                  alt=""
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="text-left">
                  <div className="inline-block">
                    <span
                      className={classNames(
                        post.category.color,
                        "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium"
                      )}
                    >
                      {post.category.name}
                    </span>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="block mt-2">
                    <p className="text-left text-xl font-semibold text-gray-900">
                      {post.title}
                    </p>
                    <p className="text-left mt-3 text-base text-gray-500">
                      {post.description}
                    </p>
                  </div>
                </div>
                <div className="text-left">
                  <div>
                    {post.techs.map((imageSrc) => (
                      <img
                        className="inline-block m-2 h-8 w-auto sm:h-8"
                        alt="tech icons"
                        src={imageSrc}
                      />
                    ))}
                  </div>
                </div>
                <div className="mt-6 flex items-center">
                  <div>
                    <p className="text-sm text-left font-medium text-gray-900">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={post.author.website}
                        className="hover:underline"
                      >
                        {post.author.name}
                      </a>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <p>{post.date}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Work;
