import React from 'react';
import { CheckIcon } from "@heroicons/react/outline";

import angular from "../../assets/icons/angular.png"
import react from "../../assets/icons/react.png"
import vue from "../../assets/icons/vuejs.png"
import ionic from "../../assets/icons/ionic.png"

import apple from "../../assets/icons/apple.png"
import android from "../../assets/icons/android.png"

import nodejs from "../../assets/icons/node-js.png"
import python from "../../assets/icons/python.png"
import redis from "../../assets/icons/redis.png"
import javascript from "../../assets/icons/javascript.png"

import aws from "../../assets/icons/aws.png"
import azure from "../../assets/icons/azure.png"
import google_cloud from "../../assets/icons/google-cloud.png"

import marvel from "../../assets/icons/marvel.png"
import sketch from "../../assets/icons/sketch.png"
import illustrator from "../../assets/icons/illustrator.png"
import figma from "../../assets/icons/figma.png"


const features_set_1 = [
  {
    name: "Web Development",
    description:
      "Using modern frontend technologies, we architect fast and scalable web-based applications and websites for whatever your business needs.",
    icon: CheckIcon,
    techs: [angular, react, vue, ionic]
  },
  {
    name: "Mobile Development",
    description:
      "Whether its Native or Hybrid, we use industry leading frameworks to build fluid mobile applications, leveraging the use of native APIs for your custom integrations.",
    icon: CheckIcon,
    techs: [apple, android, ionic, react]
  },
  {
    name: "Backend Development",
    description:
      "Scalablity, reliability, and security are Floatr's key tenents when building custom backend solutions. Ask us how we can build a backend that not only scales, but is cost effective.",
    icon: CheckIcon,
    techs: [nodejs, python, redis, javascript]
  },
];

const features_set_2 = [
  {
    name: "Cloud Deployment + Support",
    description:
      "Floatr's experience with various cloud platforms (AWS, Azure, GCP) helps us provide you with industry knowledge on your Cloud infrastructures and deployments.",
    icon: CheckIcon,
    techs: [aws, azure, google_cloud]
  },
  {
    name: "Machine Learning Development",
    description:
      "In the growing age of Data, its important for businesses to leverage their data to make better business decisions. Ask us about ways we can add ML Models to your infrastructure.",
    icon: CheckIcon,
    techs: [python, aws, azure, google_cloud]
  },
  {
    name: "UX/UI Development",
    description:
      "We can build great, fluid, and meaningful workflows and product design templates for your idea or web/mobile application.",
    icon: CheckIcon,
    techs: [figma, sketch, illustrator, marvel]
  },
];

function Services() {
  return (
    <div id="services" className="lg:flex md:block py-12 bg-gray-800">
      <div className="lg:flex-auto md:block lg:w-1/3 md:w-auto px-4 sm:px-6 lg:px-8">
        <div className="text-left">
          <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
            Services
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
            What we offer.
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500">
            Floatr harnesses our diverse skill set to provide our clients with
            the multitude of options for your business. Focus more on building
            the best product, and leave the rest to us.
          </p>
        </div>
      </div>

      <div className="lg:flex-auto md:block lg:w-1/3 md:w-auto px-4 sm:px-6 lg:px-8">
        {features_set_1.map((feature) => (
          <div key={feature.name} className="block p-2">
            <div className="text-left">
              <div className="inline-block h-8 w-8 rounded-md text-green-500">
                <feature.icon aria-hidden="true" className="inline-block" />
              </div>
              <p className="pl-4 inline-block text-lg leading-9 font-medium text-white">
                {feature.name}
              </p>
            </div>

            <div className="text-left">
              <p className="ml-12 text-base text-gray-500">
                {feature.description}
              </p>
              <div className="ml-10">
              {feature.techs.map((imageSrc) => (
                <img className="inline-block m-2 h-8 w-auto sm:h-8" alt="tech icons" src={imageSrc} />
              ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="lg:flex-auto md:block lg:w-1/3 md:w-auto px-4 sm:px-6 lg:px-8">
        {features_set_2.map((feature) => (
          <div key={feature.name} className="block p-2">
            <div className="text-left">
              <div className="inline-block h-8 w-8 rounded-md text-green-500">
                <feature.icon aria-hidden="true" className="inline-block" />
              </div>
              <p className="pl-4 inline-block text-lg leading-9 font-medium text-white">
                {feature.name}
              </p>
            </div>

            <div className="text-left">
              <p className="ml-12 text-base text-gray-500">
                {feature.description}
              </p>
              <div className="ml-10">
              {feature.techs.map((imageSrc) => (
                <img className="inline-block m-2 h-8 w-auto sm:h-8" alt="tech icons" src={imageSrc} />
              ))}
              </div>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
}

export default Services;
